import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {ReactComponent as BackSVG} from "../../../../images/icons/arrow-left.svg";
import {Link, useHistory} from "react-router-dom";
import {getUser, getVouchers} from "../../../../application/user/reducer";
import Money from "../../../common/Money";
import UserService from "../../../../application/user/userService";
import AppConfig from "../../../../application/common/AppConfig";
import {ReactComponent as GiftSVG} from "../../../../images/icons/gift.svg";
import {ReactComponent as PromotionSVG} from "../../../../images/icons/promotion.svg";
import {ReactComponent as VoucherSVG} from "../../../../images/icons/voucher.svg";
import {ReactComponent as CashbackSVG} from "../../../../images/icons/cashback.svg";
import {ReactComponent as ArrowRightSVG} from "../../../../images/icons/arrow-right.svg";
import LoginUserModal from "../../../common/user/LoginUserModal";

const UserIndexPage = (props) => {
    const [qrcodeBase64, setQrcodeBase64] = useState(null);
    const [loginModal, setLoginModal] = useState(false);
    const history = useHistory();
    const user = props.user;
    useEffect(() => {
        if (!user) {
            props.onHide()
            return;
        }
        const fetchQrcode = async () => {
            const response = await UserService.getQrcode();
            if (response) {
                setQrcodeBase64(response);  // Set the base64 string
            }
        };

        fetchQrcode();
    }, [user?.id]);
    const logout = async () => {
        try {
            await props.dispatch(UserService.logout());
            history.push("/");
        } catch (err) {
            console.log(err);
        }

    }
    return (

        <>
            <div className={"user"}>
                <div className={"user-info mb-5"}>
                    <div className={"user-info-header"}>
                        <Link to={"/"} className={"btn-back"}>
                            <BackSVG/>
                        </Link>
                    </div>
                    <div className={"user-info-body"}>

                        {!props.user.verified && (
                            <>
                                <div className={"alert alert-warning"}>
                                    Twoje konto nie jest zweryfikowane, <a href={"#"} onClick={() => setLoginModal(true)}>kliknij
                                    tutaj aby zweryfikować konto</a>
                                </div>
                                {loginModal && (
                                    <LoginUserModal onHide={() => setLoginModal(false)} show={true}/>
                                )}
                            </>
                        )}
                        <h5>
                            Cześć, {props.user.name}!
                        </h5>
                        {AppConfig.isUserQrcode() && (
                            <>
                                {qrcodeBase64 && (
                                    <>
                                        <div className={"user-info-qrcode"}>
                                            <img src={`data:image/png;base64,${qrcodeBase64}`} alt="QR Code"/>
                                        </div>
                                        <p className={"user-info-qrcode-text"}>
                                            Zeskanuj kod w restauracji, aby skorzystać z Twoich zniżek
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        {(AppConfig.isUserPoints() || AppConfig.isUserCashback() || AppConfig.isUserVouchers() || AppConfig.isUserGroups()) && (
                            <div className={"user-info-boxes nav nav-pills nav-fill flex-column w-100"}>

                                {AppConfig.isUserPoints() && (
                                    <>
                                        {props.user?.points && (
                                            <li className="nav-item">
                                                <Link to={"user/vouchers"}
                                                      className="nav-link "
                                                      aria-current="vouchers">
                                                    <GiftSVG/>
                                                    <div>
                                                        Punkty
                                                        <strong>{user?.points}</strong>
                                                    </div>
                                                </Link>
                                            </li>
                                        )}
                                    </>
                                )}
                                {AppConfig.isUserGroups() && (
                                    <>
                                        {props.user.group_name && (
                                            <li className="nav-item">
                                                <div
                                                    className="nav-link "
                                                    aria-current="vouchers">
                                                    <PromotionSVG/>
                                                    <div>
                                                        Twoja grupa
                                                        <strong>{props.user.group_name}</strong>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                    </>
                                )}
                                {AppConfig.isUserCashback() && (
                                    <>
                                        {(props.user.promotional_credits && props.user.promotional_credits.amount > 0) && (
                                            <li className="nav-item">
                                                <div
                                                    className="nav-link "
                                                    aria-current="vouchers">
                                                    <CashbackSVG/>
                                                    <div>
                                                        Cashback
                                                        <strong><Money
                                                            value={props.user.promotional_credits}/></strong>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                    </>
                                )}
                                {AppConfig.isUserVouchers() && (
                                    <li className="nav-item">
                                        <Link to={"user/vouchers"}
                                              className="nav-link "
                                              aria-current="vouchers">
                                            <VoucherSVG/>
                                            <div>
                                                Vouchery
                                                <strong>{props.vouchers.length} Dostępne</strong>
                                            </div>
                                        </Link>
                                    </li>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className={"user-menu"}>

                    <h3>
                        Menu
                    </h3>
                    <div className={"nav nav-pills nav-fill flex-column"}>

                        <li className="nav-item">
                            <Link to={"user/delete"} className="nav-link"
                                  aria-current="page">Usuń
                                konto

                                <ArrowRightSVG/>
                            </Link>
                        </li>
                        <li className="nav-item">

                            <a href={"#"} onClick={(e) => {
                                e.preventDefault();
                                logout()
                            }} className="nav-link"
                               aria-current="page">Wyloguj
                                się
                                <ArrowRightSVG/>
                            </a>
                        </li>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    user: getUser(state.user),
    vouchers: getVouchers(state.user)
})
export default withTranslation()(connect(mapStateToProps)(UserIndexPage))
